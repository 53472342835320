import tw, { styled } from "twin.macro"

import { Announcement } from "./Announcement/Announcement"
import { css } from "@emotion/react"

export const StyledHeader = styled.header<ActiveProps>`
  ${tw`flex flex-col inset-x-0 top-0 z-50 transition-all duration-100`}
  ${({ headerShouldOverlap }) => (headerShouldOverlap === "true" ? tw`sticky` : tw`sticky md:fixed`)}
  ${({ defaultLinkColor }) =>
    defaultLinkColor &&
    css`
      color: ${defaultLinkColor};
    `}
  ${({ activeBackgroundColor, defaultHoverLinkColor }) =>
    css`
      .header-panel {
        &:hover {
          color: ${defaultHoverLinkColor};
          background-color: ${activeBackgroundColor};
        }
      }
    `}
  ${({ active, isScrolled, activeBackgroundColor, defaultHoverLinkColor }) => {
    return (
      (isScrolled || active) &&
      css`
        @media (min-width: 768px) {
          .header-panel {
            color: ${defaultHoverLinkColor};
            background-color: ${activeBackgroundColor};
          }
        }
      `
    )
  }}
  ${({ defaultHoverLinkColor }) => {
    return css`
      @media (max-width: 767px) {
        .header-panel {
          color: ${defaultHoverLinkColor};
          background-color: white;
        }
      }
    `
  }}
    
  
`

export const AnnouncementOffset = tw.div`
`

export const HeaderOffset = styled.div`
  ${tw`h-5 md:h-10 transition-all duration-100`}
`

export const HeaderPanel = styled.div`
  ${tw`relative top-0`}
  ${css`
    z-index: 2;
  `}
`

export const BrandWrapper = styled.div`
  ${tw`relative flex flex-row items-center justify-between h-64px md:h-auto pt-0 pb-0 md:pt-1-6 md:pb-1 px-0 md:px-3`}
`

export const HeaderMastheadWrapper = styled.div`
  ${tw`hidden mlg:flex flex-row items-center static inset-x-7-5 h-4-5 transition-all duration-300 px-2-4 md:px-3`}
  ${({ active }) => (active === "true" ? null : tw`mlg:h-0 mlg:border-b-0`)}
`

export const HeaderMasthead = styled.div<ActiveProps>`
  ${tw``}
`

export const HeaderTop = styled.div<ActiveProps>`
  ${tw`flex`}
`

export const PanelLeft = styled.div`
  ${tw`absolute transition-all duration-300 flex-1 invisible mlg:visible mlg:flex-none flex flex-row justify-start items-center mlg:w-20 transition-all duration-300`}
  ${({ active }) => (active ? `bottom: 0; @media only screen and (min-width: 1000px) { bottom: calc(100% - 2.125rem) }` : tw`bottom-0 mlg:bottom-7`)}
`

export const PanelRight = styled.div`
  ${tw`flex-1 md:invisible mlg:flex-none flex flex-row justify-end items-center mlg:w-20`}
`

// export const HeaderMastheadOverlay = styled.div`
//   ${tw`flex justify-between items-center px-1 transition-all duration-300 border-b md:border-b-0`}
// `

export const HeaderMastheadOverlay = styled.div`
  ${tw`flex w-full md:w-auto justify-between items-center h-4-5 inset-x-0 md:inset-x-0 px-1 relative mlg:left-0 transition-all duration-300`}
  ${({ active }) => (active ? `bottom:0;` : tw`bottom-0 mlg:bottom-0-6`)}
`

export const FlexGrowLeft = styled.div`
  ${tw`flex flex-grow max-w-1 md:max-w-none items-center`}
`
export const FlexGrowRight = styled.div`
  ${tw`flex flex-grow max-w-1 md:max-w-none items-center justify-end`}
`

export const HeaderMastheadOverlayLeft = styled.div`
  ${tw`mlg:hidden flex flex-row justify-start items-center`}
`

export const HeaderMastheadOverlayRight = styled.div`
  ${tw`flex flex-row justify-end items-center`}
`

export const RightSearchWrapper = styled.div`
  ${tw`hidden mlg:block`}
`
export const PanelLeftWrapper = styled.div`
  ${tw`mlg:w-20`}
`

export const DesktopAnnouncement = styled(Announcement)<ActiveProps>`
  ${tw`flex-1 hidden mlg:flex transition-none pointer-events-none`}
`

export const MobileAnnouncement = styled(Announcement)<MobileProps>`
  ${tw`h-4 md:h-4 md:border-b md:border-t md:border-b mlg:border-b-0  mlg:hidden`}
`

type MobileProps = {
  active: boolean
}

type ActiveProps = {
  active: "true" | "false"
}

export const HeaderIconWrapper = styled.div`
  ${tw`flex flex-row items-center justify-center min-w-50px min-h-50px md:min-h-0 md:min-w-0`}
`

export const HeaderIconInner = styled.div`
  ${tw`w-auto h-auto relative`}
  ${css`
    padding: 5px;
  `}
`

export const Desktop = tw.div`
  hidden md:block
`
